import React, { useState } from "react"
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      reports {
        id
        date
        title
        document {
          url
        }
      }
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function ReportsPresentations({ data, pageContext }) {
  const { reports } = data.datoCmsInvestorRelationsPage

  const [years, setYears] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);

  function reportsByYear(reports) {
    const unsortedYearsObj = reports.reduce((acc, report) => {
      const reportYear = (new Date(report.date)).getFullYear()
      if (!acc[reportYear]) acc[reportYear] = [report]
      else acc[reportYear].push(report)
      return acc
    }, {})

    // populating years on initial "sort"
    if (!years) {
      setYears(Object.keys(unsortedYearsObj).sort((a, b) => b - a))
    }

    const sortedYearsArr = Object.entries(unsortedYearsObj).sort(([a], [b]) => b - a)
    sortedYearsArr.forEach(([_year, reports]) => {
      reports.sort(({ date: a }, { date: b }) => (
        new Date(b) - new Date(a)
      ))
    })

    return sortedYearsArr
  }

  return (
    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
      <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <PageTitle />
            <div className="flex flex--baseline">
              <LocalisedShow />
              <select className="select" onChange={(e) => setYearFilter(e.target.value)} onBlur={(e) => setYearFilter(e.target.value, 10)}>
                <LocalisedLatest />
                {years && years.map(year => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div>
              {reportsByYear(reports).filter(([year]) => yearFilter ? year === yearFilter : true).map(([year, reports]) => (
                <div className="margin-top" key={year}>
                  <h2>{year}</h2>
                  <table>
                    <tbody>
                      {reports.map(report => (
                        <tr>
                          <td><a href={report.document.url} target="_blank" rel="noreferrer">{report.title}</a></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

const PageTitle = (years) => {
  const language = React.useContext(LocaleContext).language
  return (
      <h1>{language=='sv' ? 'Finansiella rapporter & presentationer' : 'Financial reports & presentations'}</h1>
  )
}

const LocalisedShow = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <div className="margin-right-small">{language=='sv' ? 'Visa:' : 'Show:'}</div>
  )
}

const LocalisedLatest = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <option value="">{language=='sv' ? 'Senaste rapporter' : 'Latest reports'}</option>
  )
}